<template>
    <div>
        <g-checkbox-group v-model="model">
            <div v-for="option in orderedOptions" :key="option.id">
                <g-checkbox :value="option.value">{{ option.value }}</g-checkbox>
            </div>
        </g-checkbox-group>
    </div>
</template>

<script>
    import FieldInputMixin from './FieldInputMixin';

    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'MultipleCheckboxesInput',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [FieldInputMixin],

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {
                /**
                 * The default model
                 *
                 * @type {string|null}
                 */
                default_model: []
            };
        }
    };
</script>
